// These flags are compiled by Gatsby at build time.
const STATIC_FEATURE_FLAGS = {
  /** Move the US website from / to /us */
  enableUsDirectory: process.env["GATSBY_FEATURE_US_DIRECTORY"],
} as const

/**
 * Check to see if a static feature flag is enabled.
 * This method is synchronous so can be used during build time.
 */
export function staticFeatureFlagEnabled(key: keyof typeof STATIC_FEATURE_FLAGS): boolean {
  return "true" === STATIC_FEATURE_FLAGS[key]
}

/**
 * Check to see if a feature flag is enabled.
 * Note, this is async as in the future, it may call a feature flag API.
 */
export async function featureFlagEnabled(key: keyof typeof STATIC_FEATURE_FLAGS): Promise<boolean> {
  return staticFeatureFlagEnabled(key)
}
