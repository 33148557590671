import { useWishlistContext } from "@hooks/useWishlist"
import { CustomerContext } from "@providers/global/customer"
import * as Sentry from "@sentry/gatsby"
import { useCallback, useContext, useEffect, useState } from "react"
import { useAnalytics } from "./useAnalytics"
import { useApp } from "./useApp"
import { useCheckout, useCheckoutContext } from "./useCheckout"
import { useCore } from "./useCore"
import { useFunctions } from "./useFunctions"
import { useFunctionsGatsby } from "./useFunctionsGatsby"
import { useKlaviyo } from "./useKlaviyo"
import { useLocalisation, useLocalisationContext } from "./useLocalisation"
import { useRoutes } from "./useRoutes"
import { useShopify } from "./useShopify"
import { v4 as uuid } from "uuid"

export const useCustomerContext = () => {
  const customerData: any = useContext(CustomerContext)
  return { ...customerData }
}

export const useCustomerSession = () => {
  const { setSessionId } = useCustomerContext()

  const {
    helpers: { storage },
  } = useCore()

  const createSessionId = (): string => {
    const existingSessionId = getSessionId()
    if (existingSessionId) {
      setSessionId(existingSessionId)
      return existingSessionId
    }

    const sessionId = uuid()
    storage.set("session-id", sessionId)
    Sentry.setTag("session-id", sessionId)
    setSessionId(sessionId)
    return sessionId
  }

  const getSessionId = (): string => {
    const existingSessionId = storage.get("session-id")
    return existingSessionId
  }

  return {
    createSessionId,
    getSessionId,
  }
}

export const useCustomerAccessToken = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_ACCESS_TOKEN_CREATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const { setCustomer } = useCustomerContext()

  const { updateCustomer } = useCheckout()

  const { useMutation, useQuery } = useShopify()

  const [customerAccessTokenCreate] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE)
  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    skip: true,
  })

  const getCustomer = useCallback(async () => {
    const customerTokens = storage.get(keys?.customer)

    if (customerTokens?.accessToken) {
      try {
        const {
          data: { customer, customerUserErrors },
        } = await getCustomerQuery({
          customerAccessToken: customerTokens?.accessToken,
        })

        if (!customerUserErrors?.length && customer !== null) {
          Sentry.setUser({ id: customer.id })
          setCustomer(customer)
        } else {
          setCustomer(null)
          storage.remove(keys?.customer)
        }
      } catch (err) {
        setCustomer(null)
        Sentry.captureException(err)
      }
    } else {
      setCustomer(null)
    }
  }, [getCustomerQuery, setCustomer, keys, storage])

  const createAccessToken = useCallback(
    async (email, password) => {
      try {
        const {
          data: {
            customerAccessTokenCreate: { customerAccessToken, customerUserErrors },
          },
        } = await customerAccessTokenCreate({
          variables: { input: { email, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          storage.set(keys?.customer, { accessToken, expiresAt })
          updateCustomer(accessToken)
          getCustomer()
        }

        return { customerAccessToken, customerUserErrors }
      } catch (err) {
        Sentry.captureException(err)
        return {
          customerUserErrors: [
            {
              message: err.message,
            },
          ],
        }
      }
    },
    [customerAccessTokenCreate, updateCustomer, getCustomer]
  )

  return { createAccessToken, getCustomer }
}

export const useCustomerAuthentication = () => {
  const {
    helpers: { storage },
  } = useCore()
  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const { navigate } = useLocalisation()
  const { customer } = useCustomerContext()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { getCustomer } = useCustomerAccessToken()
  const customerTokens = storage.get(keys?.customer)
  const { getUrlParameter } = useRoutes()

  if (!customerTokens?.accessToken || new Date(customerTokens?.expiresAt) <= new Date()) {
    storage.remove(keys?.customer)
    if (isBrowser && !getUrlParameter("returnUrl")) {
      navigate(`${routes?.LOGIN}?returnUrl=${window.location.pathname}`)
    }
    return false
  }

  if (!customer?.id) getCustomer()
  return true
}

export const useCustomerNotAuthenticated = () => {
  const {
    helpers: { storage, isBrowser },
  } = useCore()
  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const { navigate } = useLocalisation()
  const customerTokens = storage.get(keys?.customer)

  if (customerTokens?.accessToken && new Date(customerTokens?.expiresAt) >= new Date()) {
    if (isBrowser) navigate(routes?.HOMEPAGE)
    return false
  }
  return true
}

export const useCustomerRegister = () => {
  const {
    helpers: { isBrowser },
    graphql: {
      mutations: { CUSTOMER_CREATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const { useMutation, useQuery } = useShopify()
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const { createAccessToken } = useCustomerAccessToken()
  const { navigate } = useLocalisation()
  const { addCountryTag, customerTags, updateCustomerMetafield, customerTagsAndMetafields } = useFunctions()
  const { updateCustomerEmailInterestsAndFrequency } = useFunctionsGatsby()
  const { trackSignup } = useAnalytics()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    acceptsMarketing: false,
    phone: "",
  })

  const [customerCreate] = useMutation(CUSTOMER_CREATE)
  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    skip: true,
  })

  const createCustomer = useCallback(
    async ({ ...userData }, tagsList, metafields) => {
      setLoading(true)
      setErrors([])
      try {
        const {
          data: {
            customerCreate: { customerUserErrors: errors },
          },
        } = await customerCreate({
          variables: { input: { ...userData } },
        })
        const tags = addCountryTag(tagsList)
        if (errors?.length) {
          setErrors(errors)
          setLoading(false)
        } else {
          const { customerUserErrors, customerAccessToken } = await createAccessToken(userData?.email, userData?.password)
          if (!customerUserErrors?.length) {
            trackSignup("User/Pass")
            if (tags.length > 0 && customerAccessToken?.accessToken && metafields) {
              // Update both tags and metafields
              try {
                const {
                  data: { customer },
                } = await getCustomerQuery({
                  customerAccessToken: customerAccessToken?.accessToken,
                })
                customerTagsAndMetafields(customer?.id, tags, metafields)
                if (isBrowser) {
                  navigate(routes?.DASHBOARD)
                }
              } catch (err) {
                Sentry.captureException(err)
              }
            } else if (tags.length > 0 && customerAccessToken?.accessToken) {
              // Update tags only
              try {
                const {
                  data: { customer },
                } = await getCustomerQuery({
                  customerAccessToken: customerAccessToken?.accessToken,
                })
                customerTags(customer?.id, tags)
                await updateCustomerEmailInterestsAndFrequency(userData?.email, [], "", tags, customerAccessToken?.accessToken)
                if (isBrowser) {
                  navigate(routes?.DASHBOARD)
                }
              } catch (err) {
                Sentry.captureException(err)
              }
            } else if (customerAccessToken?.accessToken && metafields) {
              // Update metafields only
              try {
                const {
                  data: { customer },
                } = await getCustomerQuery({
                  customerAccessToken: customerAccessToken?.accessToken,
                })
                await updateCustomerMetafield(metafields.key, metafields.namespace, null, metafields.value, customer?.id)
                if (isBrowser) {
                  navigate(routes?.DASHBOARD)
                }
              } catch (err) {
                Sentry.captureException(err)
              }
            } else {
              if (isBrowser) {
                navigate(routes?.DASHBOARD)
              }
            }
          } else {
            setErrors(customerUserErrors)
            setLoading(false)
          }
        }
      } catch (err) {
        Sentry.captureException(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerCreate, createAccessToken, navigate, routes]
  )

  return { createCustomer, data, setData, loading, errors }
}

export const useCustomerLogin = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { createAccessToken } = useCustomerAccessToken()
  const { navigate } = useLocalisation()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "", password: "" })
  const { setIsWishlistInitialized } = useWishlistContext()
  const { getUrlParameter } = useRoutes()
  const { trackLogin } = useAnalytics()
  const isCheckout = getUrlParameter("ref") === "checkout"
  const returnUrl = getUrlParameter("returnUrl")

  const loginCustomer = useCallback(
    async ({ ...userData }) => {
      setLoading(true)
      setErrors([])

      try {
        const { customerUserErrors } = await createAccessToken(userData?.email, userData?.password)

        if (!customerUserErrors?.length) {
          trackLogin("User/Pass")
          setIsWishlistInitialized(false)
          if (isCheckout) {
            navigate(routes?.LOGIN_LOADING, { state: { checkout: true } })
          } else if (isBrowser) {
            navigate(returnUrl || routes?.DASHBOARD)
          }
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        Sentry.captureException(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, createAccessToken, navigate, routes]
  )

  return { loginCustomer, data, setData, loading, errors }
}

export const useCustomerLogout = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CHECKOUT_CUSTOMER_DISASSOCIATE },
    },
  } = useCore()
  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()

  const { setCustomer } = useCustomerContext()
  const { setCheckout } = useCheckoutContext()
  const { navigate } = useLocalisation()
  const { contextCountry } = useLocalisationContext()
  const { track } = useKlaviyo()
  const { setWishlist, setIsWishlistInitialized } = useWishlistContext()
  const checkoutId = storage.get(keys?.checkout)
  const { checkoutNormaliser } = useShopify()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { useMutation } = useShopify()

  const [checkoutCustomerDisassociate] = useMutation(CHECKOUT_CUSTOMER_DISASSOCIATE)

  const logoutCustomer = useCallback(async () => {
    const {
      data: { checkoutCustomerDisassociateV2: data },
    } = await checkoutCustomerDisassociate({
      variables: {
        countryCode: contextCountry,
        checkoutId,
      },
    })
    setCheckout(checkoutNormaliser(data?.checkout))
    storage.set(keys?.checkout, data?.checkout?.id)
    storage.remove(keys?.customer)
    track("Account Logout", false)
    setCustomer(null)
    setIsWishlistInitialized(false)
    setWishlist([])
    if (isBrowser) {
      navigate(routes?.LOGIN)
    }
  }, [checkoutCustomerDisassociate, setCheckout, setCustomer, keys, storage, routes, navigate])

  return { logoutCustomer }
}

export const useCustomerRecover = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_RECOVER },
    },
  } = useCore()
  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()

  const { useMutation } = useShopify()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ email: "" })
  const [success, setSuccess] = useState(false)

  const [customerRecover] = useMutation(CUSTOMER_RECOVER)

  const recoverCustomer = useCallback(
    async email => {
      setLoading(true)
      setErrors([])

      try {
        const {
          data: {
            customerRecover: { customerUserErrors },
          },
        } = await customerRecover({
          variables: { email },
        })

        if (!customerUserErrors?.length) {
          storage.remove(keys?.customer)
          setLoading(false)
          setSuccess(true)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        Sentry.captureException(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerRecover, storage, keys, routes]
  )

  return { recoverCustomer, data, setData, loading, errors, success }
}

export const useCustomerAccount = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_RESET, CUSTOMER_ACTIVATE },
    },
  } = useCore()
  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { navigate } = useLocalisation()
  const { encodeCustomerId, useMutation } = useShopify()
  const { updateCustomer } = useCheckout()
  const { track } = useKlaviyo()
  const { updateCustomerEmailInterestsAndFrequency } = useFunctionsGatsby()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [data, setData] = useState({ password: "" })

  const [customerReset] = useMutation(CUSTOMER_RESET)
  const [customerActivate] = useMutation(CUSTOMER_ACTIVATE)

  const resetCustomer = useCallback(
    async (customerId, resetToken, password) => {
      setLoading(true)
      setErrors([])

      try {
        const id = encodeCustomerId(customerId)

        const {
          data: {
            customerReset: { customerAccessToken, customerUserErrors },
          },
        } = await customerReset({
          variables: { id, input: { resetToken, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          updateCustomer(accessToken)
          storage.set(keys?.customer, { accessToken, expiresAt })
          track("Account Reset", false)
          if (isBrowser) navigate(routes?.DASHBOARD)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        Sentry.captureException(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerReset, updateCustomer, storage, keys, routes]
  )

  const activateCustomer = useCallback(
    async (customerId, activationToken, password) => {
      setLoading(true)
      setErrors([])

      try {
        const id = encodeCustomerId(customerId)

        const {
          data: {
            customerActivate: { customer, customerAccessToken, customerUserErrors },
          },
        } = await customerActivate({
          variables: { id, input: { activationToken, password } },
        })

        if (!customerUserErrors?.length) {
          const { accessToken, expiresAt } = customerAccessToken
          updateCustomer(accessToken)
          storage.set(keys?.customer, { accessToken, expiresAt })
          await updateCustomerEmailInterestsAndFrequency(customer.email, [], "", customer.tags, accessToken)
          track("Account Activate", false)
          if (isBrowser) navigate(routes?.DASHBOARD)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        Sentry.captureException(err)
        setErrors([err])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerActivate, updateCustomer, storage, keys, routes]
  )

  return { resetCustomer, activateCustomer, data, setData, loading, errors }
}

export const useCustomerOrders = first => {
  const {
    helpers: { storage },
    graphql: {
      queries: { GET_CUSTOMER_ORDERS },
    },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { useQuery } = useShopify()
  const { contextCountry } = useLocalisationContext()
  const { accessToken: customerAccessToken } = storage.get(keys?.customer)

  const { data, loading, error } = useQuery(GET_CUSTOMER_ORDERS, {
    variables: {
      countryCode: contextCountry,
      customerAccessToken,
      first,
      reverse: true,
    },
  })
  const orders = data?.customer?.orders?.edges?.filter(
    ({ node }) => ["PAID", "PARTIALLY_REFUNDED"].includes(node?.financialStatus) && node?.currentTotalPrice?.amount !== "0.0"
  )
  return { orders, loading, error }
}

export const useCustomerAddress = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_ADDRESS_CREATE, CUSTOMER_ADDRESS_UPDATE, CUSTOMER_ADDRESS_DELETE, CUSTOMER_DEFAULT_ADDRESS_UPDATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
    setNewAddressId,
  } = useApp()
  const { useMutation, useLazyQuery } = useShopify()
  const { track } = useKlaviyo()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const initialData = {
    address1: "",
    address2: "",
    city: "",
    company: "",
    country: "",
    firstName: "",
    lastName: "",
    phone: "",
    province: "",
    zip: "",
  }
  const [address, setAddress] = useState({ ...initialData, id: "", action: "" })
  const [addresses, setAddresses] = useState([])
  const { accessToken: customerAccessToken } = storage.get(keys?.customer) || {}

  const [customerAddressCreate] = useMutation(CUSTOMER_ADDRESS_CREATE)
  const [customerAddressUpdate] = useMutation(CUSTOMER_ADDRESS_UPDATE)
  const [customerAddressDelete] = useMutation(CUSTOMER_ADDRESS_DELETE)
  const [customerDefaultAddressUpdate] = useMutation(CUSTOMER_DEFAULT_ADDRESS_UPDATE)

  const filterData = address =>
    Object.keys(address)
      .filter(key => Object.keys(initialData).includes(key))
      .reduce((obj, key) => {
        obj[key] = address[key]
        return obj
      }, {})

  const [getAll, { data, loading }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: {
      customerAccessToken,
    },
  })

  useEffect(() => {
    getAll()
  }, [saving])

  useEffect(() => {
    if (data?.customer)
      setAddresses(
        data?.customer?.addresses?.edges?.map(({ node }) => ({
          ...node,
          default: node?.id === data?.customer?.defaultAddress?.id,
        }))
      )
  }, [data])

  const createAddress = useCallback(
    async address => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressCreate: { customerUserErrors, customerAddress },
          },
        } = await customerAddressCreate({
          variables: { customerAccessToken, address: filterData(address) },
        })

        if (!customerUserErrors?.length) {
          setAddress({ ...initialData, id: "", action: "" })
          setNewAddressId(customerAddress.id)
          setSaving(false)
          track("Address Add", filterData(address))
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        Sentry.captureException(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, setAddress, setNewAddressId, customerAddressCreate, filterData, initialData]
  )

  const updateAddress = useCallback(
    async (id, address) => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressUpdate: { customerUserErrors },
          },
        } = await customerAddressUpdate({
          variables: { customerAccessToken, id, address: filterData(address) },
        })

        if (!customerUserErrors?.length) {
          setAddress({ ...initialData, id: "", action: "" })
          setSaving(false)
          track("Address Update", filterData(address))
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        Sentry.captureException(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, setAddress, customerAddressUpdate, filterData, initialData]
  )

  const defaultAddress = useCallback(
    async addressId => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerDefaultAddressUpdate: { customerUserErrors },
          },
        } = await customerDefaultAddressUpdate({
          variables: { addressId, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
          track("Address Default", addressId)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        Sentry.captureException(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, customerDefaultAddressUpdate]
  )

  const deleteAddress = useCallback(
    async id => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressDelete: { customerUserErrors },
          },
        } = await customerAddressDelete({
          variables: { id, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
          track("Address Delete", id)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        Sentry.captureException(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, customerAddressDelete]
  )

  return {
    addresses,
    setAddress,
    address,
    createAddress,
    updateAddress,
    defaultAddress,
    deleteAddress,
    filterData,
    initialData,
    loading,
    saving,
    errors,
  }
}

export const useCustomerDetails = () => {
  const {
    helpers: { storage },
    graphql: {
      mutations: { CUSTOMER_UPDATE },
    },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { useMutation } = useShopify()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    acceptsMarketing: false,
  }
  const [customerUpdate] = useMutation(CUSTOMER_UPDATE)
  const { customer: initialCustomer, setCustomer: saveCustomer } = useCustomerContext()
  const [customer, setCustomer] = useState(initialCustomer)
  const { accessToken: customerAccessToken } = storage.get(keys?.customer) || { accessToken: "" }

  const filterData = (data, hidePassword = false) => {
    return hidePassword
      ? Object.keys(data)
          .filter(key => Object.keys(initialData).includes(key))
          .filter(key => key !== "password")
          .reduce((obj, key) => {
            obj[key] = data[key]
            return obj
          }, {})
      : Object.keys(data)
          .filter(key => Object.keys(initialData).includes(key))
          .reduce((obj, key) => {
            obj[key] = data[key]
            return obj
          }, {})
  }

  const updateCustomer = useCallback(
    async customer => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerUpdate: { customerUserErrors },
          },
        } = await customerUpdate({
          variables: { customerAccessToken, customer: filterData(customer) },
        })

        if (!customerUserErrors?.length) {
          saveCustomer(prevCustomer => ({
            ...prevCustomer,
            ...filterData(customer, true),
          }))
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        Sentry.captureException(err)
        setErrors([err])
        setSaving(false)
      }
    },
    [setSaving, setErrors, setCustomer, customerUpdate, filterData]
  )

  return { customer, setCustomer, updateCustomer, saving, errors }
}

export const useShowPassword = () => {
  const [showPassword, setShowPassword] = useState(false)
  return { showPassword, setShowPassword }
}
