module.exports = [{
      plugin: require('../../node_modules/.pnpm/@sentry+gatsby@7.106.1_gatsby@5.13.3_react@18.2.0/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.13.3_patch_hash=6zrlgscmip7kno67loq73ogety_@babel+plugin-syntax-flow@7.24.7_@babel+p_bve42hjurlbqv4uy5slhcgxe7i/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
