import type { Site } from "~/lib/sites"

import { staticFeatureFlagEnabled } from "../lib/feature-flags"

export const sites: Site[] = [
  {
    handle: "au",
    urlPrefix: "au",
    locale: "en-AU",
    currencyCode: "AUD",
    countryCodes: ["AU"],
    __primaryCountryCode: "AU",
  },
  {
    handle: "nz",
    urlPrefix: "nz",
    locale: "en-NZ",
    currencyCode: "NZD",
    countryCodes: ["NZ"],
    __primaryCountryCode: "NZ",
  },
  {
    handle: "hk",
    urlPrefix: "hk",
    locale: "en-HK",
    currencyCode: "HKD",
    countryCodes: ["HK"],
    __primaryCountryCode: "HK",
  },
  {
    handle: "sg",
    urlPrefix: "sg",
    locale: "en-SG",
    currencyCode: "SGD",
    countryCodes: ["SG"],
    __primaryCountryCode: "SG",
  },
  {
    handle: "uk",
    urlPrefix: "uk",
    locale: "en-GB",
    currencyCode: "GBP",
    countryCodes: ["UK", "GB"],
    __primaryCountryCode: "GB",
  },
  {
    handle: "us",
    urlPrefix: staticFeatureFlagEnabled("enableUsDirectory") ? "us" : "",
    locale: "en-US",
    currencyCode: "USD",
    countryCodes: ["US", "ROW"],
    __primaryCountryCode: "US",
  },
]
