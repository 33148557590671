import { useEffect } from "react"
import { parseGid } from "@shopify/hydrogen-react"

import { debug } from "~/lib/logger.client"

import { useCustomerContext } from "~/hooks/useCustomer"

const d = debug.extend("ImpactAnalytics")

/**
 * Impact is an affiliate platform which handles paying out commission to partners when they
 * send customers to our site.
 *
 * This component listens for changes to authentication status and calls the identify method
 * added by the "Universal Tracking Tag".
 */
export function ImpactAnalytics() {
  const { customer, customerStatus, sessionId, sessionIdStatus } = useCustomerContext()

  // Call the identify method when we have enough context.
  useEffect(() => {
    d("Identify effect called with dependencies.", { customer, customerStatus, sessionId, sessionIdStatus })

    // Session ID not loaded yet, do nothing.
    if ("UNRESOLVED" === sessionIdStatus) return

    // Customer not loaded yet, do nothing.
    if ("UNRESOLVED" === customerStatus) return

    // @ts-expect-error We know this is loaded.
    const impact = window.ire

    // Hash the email attribute.
    generateHash(customer?.email ?? "").then(emailHash => {
      // Construct a payload for the identify function.
      const identifyPayload = {
        customerEmail: emailHash,
        customProfileId: sessionId ?? "",
        customerId: customer ? `${parseGid(customer.id).id}` : "",
      }

      // Call the impact "identify" method.
      impact("identify", identifyPayload)
      d("Called identify method with payload.", { payload: identifyPayload })
    })
  }, [customer, customerStatus, sessionId, sessionIdStatus])

  return null
}

/**
 * The impact "customerEmail" attribute is hashed with sha1.
 */
async function generateHash(value: string): Promise<string> {
  // Return empty string if provided.
  if ("" === value) return value

  // Hash and return the email address.
  const enc = new TextEncoder()
  const hash = await crypto.subtle.digest("SHA-1", enc.encode(value))
  return Array.from(new Uint8Array(hash))
    .map(v => v.toString(16).padStart(2, "0"))
    .join("")
}
